import styled, { keyframes } from "styled-components";

const showNotification = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
`;
const hideNotification = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
`;
export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10000;

  .item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 200px;
    overflow: hidden;
    animation: ${hideNotification} 300ms ease-out;
    animation-fill-mode: forwards;
    transform: translateY(-100%);

    &.visible {
      animation: ${showNotification} 300ms ease-out;
      animation-fill-mode: forwards;
      transform: translateY(0%);
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 200px;
      background: rgba(255, 75, 75, 0.75);
      display: flex;
      flex-flow: column nowrap;
      overflow: auto;
    }
  }
`;
export const ErrorMessage = styled.p`
  word-wrap: break-word;
  width: 100%;
  margin: auto 0;
  color: var(--text-invert-primary);
  font-size: 14px;
  text-align: left;
  text-shadow: 0 0 0;
`;
export const ErrorDescription = styled.p`
  word-wrap: break-word;
  width: 100%;
  color: var(--text-invert-primary);
  font-size: 12px;
  text-align: left;
`;
