"use client";

import { useServerInsertedHTML } from "next/navigation";
import React from "react";
import { ServerStyleSheet, StyleSheetManager } from "styled-components";

import { useSaveTracking } from "@/hooks/tracking";

export const StyledComponentsRegistry: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [stylesheet] = React.useState(() => new ServerStyleSheet());

  useServerInsertedHTML(() => {
    const styles = stylesheet.getStyleElement();
    stylesheet.instance.clearTag();
    return <>{styles}</>;
  });

  if (typeof window !== "undefined") return <>{children}</>;

  return (
    <StyleSheetManager sheet={stylesheet.instance}>
      {children}
    </StyleSheetManager>
  );
};

export function ClientHandler() {
  useSaveTracking();
  return null;
}

export { default as T } from "@marginly/ui/components/typography";
