"use client";

import Typography from "@marginly/ui/components/typography";
import { HeaderM } from "@marginly/ui/constants/classnames";

import Space from "@/components/common/space";

import { Button, Wrapper } from "./ip-stub.styled";

const LANDING_LINK = "https://marginly.com/";

export function IpStub() {
  return (
    <Wrapper>
      <div>
        <div className="image-wrapper">
          <img src="/images/globe.svg" alt="" />
        </div>
        <Space height={40} heightMobile={40} />
        <div className="text-wrapper">
          <Typography className={HeaderM}>You can’t use App</Typography>
          <Space height={8} heightMobile={8} />
          <Typography>
            Marginly App is unavailable in your country or you have VPN enabled.
          </Typography>
        </div>
        <Space height={48} heightMobile={48} />
        <Button href={LANDING_LINK}>Well, OK</Button>
      </div>
    </Wrapper>
  );
}
