"use client";

import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;

  &.no-menu {
    background: transparent;
  }
  &.swap {
    .input {
      width: 100%;
    }
    .input__span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .action,
  .headerM,
  .headerS,
  .headerL {
    font-weight: 400 !important;
    font-variation-settings: "wght" 700 !important;
  }

  .nobr {
    word-break: keep-all;
  }
  &.wrapper--shadowed {
    box-shadow: 0px 8px 32px rgba(51, 20, 0, 0.16);
  }

  &.deposit {
    .disabled {
      pointer-events: none;
      opacity: 0.24;
      .input__area {
        background: var(--fill-secondary, rgba(204, 187, 184, 0.16));
      }
    }
    button.notEnoughFunds {
      background: var(--fill-negative);
      .action {
        color: var(--text-primary-on-dark);
      }
    }
  }

  &.connect {
    bottom: 8px;
    left: 8px;
    right: 8px;
    position: absolute;
    border-radius: 32px;
    max-width: 608px;
  }
  &.bad {
    background: #fdf0f7;
  }
  &.good {
    background: #f0faeb;
    .trade__icon {
      background: #e8eedf;
    }
    .trade__percents {
      color: #40bf00;
    }
  }

  .error-text {
    color: var(--text-negative);
  }

  .icon-alert {
    width: 32px;
    height: 32px;
  }
  .textfield .suggestion {
    left: -16px;
  }

  .textfield.showSuggestions {
    margin-bottom: 30px;
    .suggestion {
      opacity: 1;
      z-index: 9;
      pointer-events: all;
    }
  }
  .graph__container {
    margin: 0 -24px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
    }
  }

  .range-slider__container.not-focused {
    z-index: 0;
  }

  .blur {
    display: none;
  }
  &.blured {
    position: relative;
    .blur {
      display: block;
      z-index: 501;
      position: absolute;
      left: 0;
      top: 0;
      background: transparent;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(8px);

      @media (min-width: 1024px) {
        display: none;
      }
      .focused {
        z-index: 700;
        position: relative;
      }
    }
  }

  &.success {
    background: var(--background-primary);
    position: relative;
    min-height: 100dvh;
  }
  .success {
    &__content {
      position: absolute;
      left: 50%;
      top: 24%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
      max-width: calc(100% - 96px);
      @media (min-width: 768px) {
        max-width: 100%;
      }
    }

    &__title {
      font-size: 24px;
      font-variation-settings: "wght" 700;
      line-height: 32px;
      letter-spacing: 0.02em;
      .nobr {
        margin-left: 8px;
      }
      @media (min-width: 768px) {
        br {
          display: none;
        }
      }
    }
    &__coin_row {
      width: 100%;
      @media (min-width: 768px) {
        width: 300px;
      }
    }
  }

  @media (min-width: 768px) {
    .success {
      &__content {
        max-width: 284px;
        position: relative;
        margin-top: 184px;
      }
    }
    &.success {
      button {
        margin: 48px auto 0;
        max-width: 272px;
      }
    }
    &.earn,
    &.history {
      width: 100%;
    }
  }

  @media (min-width: 1024px) {
    max-width: 1140px;
    margin: 0 auto;
    border-radius: var(--rounding-radius-xxxl, 48px);
    background: var(--background-secondary, #f7f4f3);
    padding: var(--spacing-space-24, 24px);

    &.wrapper--trade {
      display: block;
      background: transparent;
      max-width: 1344px;
      margin: -12px auto 0;
    }
    .hide-desktop {
      display: none;
    }
    .success {
      &__content {
        max-width: 400px;
      }
    }

    &.w520 {
      max-width: calc(520px + 64px + 64px);
      padding: 0 64px;
    }
  }

  .swap__title {
    text-align: center;
  }

  @media (min-width: 1140px) {
    padding-top: 32px;
    .success {
      &__content {
        max-width: 560px;
      }
    }
  }
  @media (max-width: 1024px) {
    &.wrapper--trade {
      min-height: 100vh;
      min-height: 100dvh;
    }
    .headerL {
      font-size: 32px;
    }
    .swap__title {
      text-align: left;
    }
  }

  .hasValue {
    .input__hint {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      margin: 0;
    }
  }

  &.alltrades {
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 100%;
      padding: 0 64px;
    }
  }

  @media (min-width: 1440px) {
    min-height: 624px;
  }
`;

export const HideMobile = styled.div`
  @media (max-width: 1023px) {
    display: none !important;
  }
`;

export const HideDesktop = styled.div`
  @media (min-width: 1023px) {
    display: none !important;
  }
`;

export const GlobalWrapper = styled.div`
  margin-top: 72px;
  @media (min-width: 1024px) {
    margin-left: 376px;
    margin-right: 48px;
    margin-top: 104px;
  }
  .action,
  .headerM,
  .headerS,
  .headerL {
    font-weight: 400;
    font-variation-settings: "wght" 700;
  }
`;
