"use client";

import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { t } from "ttag";
import {
  CallExecutionError,
  ContractFunctionExecutionError,
  ContractFunctionRevertedError,
  ExecutionRevertedError,
} from "viem";

import {
  ErrorDescription,
  ErrorMessage,
  Wrapper,
} from "@/components/common/notifications.styled";
import { NOTIFICATION_VISIBLE_MS, SWIPEABLE_CONFIG } from "@/constants/common";
import { useRx } from "@/contexts/rx";
import { Notification } from "@/types/common";
import { hasKey } from "@/util/check";
import { getEntries } from "@/util/core";
import { isWithExactMessage, isWithHiddenProp } from "@/util/error";

import { Noop } from "../util";
import { NotificationComponent } from "./notification";

const detailsMapping: Record<string, string> = {
  "Couldn't build route: bad price limit": "Increase the margin amount.",
};

const getUserErrorMessage = (error: Error) => {
  const { message } = error;

  if (!message) return message;

  if (
    ["user", "rejected"].every((word) => message.toLowerCase().includes(word))
  ) {
    return "User rejected request";
  }

  if (
    ["transaction", "underpriced"].every((word) =>
      message.toLowerCase().includes(word),
    )
  ) {
    return "Transaction underpriced. Please try to enlarge gas limit or reset account nonce";
  }

  if (
    ["missing", "revert", "data"].every((word) =>
      message.toLowerCase().includes(word),
    )
  ) {
    return "Transaction failed. Please try to enlarge gas limit or reset account nonce";
  }

  return error instanceof ContractFunctionExecutionError
    ? t`Contract execution error:`
    : t`Error occurred.`;
};

const getReasonDescription = (reason?: string) => {
  switch (reason) {
    case "STF":
    case "ST":
      return t`Not enough funds in pool.`;
    case "ZA": // zero amount
      return t`You cannot open position with zero amount.`;
    case "MC": // margin call
      return t`Your margin will drop below the critical threshold.`;
    case "LessThanMinimalAmount":
    case "MA": // minimum amount
      return t`Please increase trade amount.`;
    case "EL": // exceed limit
      return t`The protocol has reached the global limit on the asset. Actions which increase asset balance are not allowed`;
    case "SL": // slippage
      return t`Your trade would result in more than 2% slippage. Please wait until the  external AMM liquidity conditions improve and try again.`;
    case "U": // uninitialized
      return t`Position in uninitialized state`;
    case "L": // lend position
      return t`You cannot close lend position`;
    default:
      console.warn(`Unknown revert reason: ${reason}`);
  }

  return t`Please try again later.${reason ? ` (${reason})` : ""}`;
};

const ErrorNotification = ({
  error,
  swiped,
  onClose,
}: {
  error: Error;
  swiped?: boolean;
  onClose?: () => void;
}) => {
  const [visible, setVisible] = React.useState(
    !isWithHiddenProp(error) || !error.hidden,
  );

  let message = isWithExactMessage(error)
    ? error.message
    : getUserErrorMessage(error);
  let description = "";

  // TODO refactor
  if (error instanceof ContractFunctionExecutionError) {
    const { cause } = error;

    if (cause instanceof ContractFunctionRevertedError) {
      const { reason, data } = cause;

      description = reason
        ? getReasonDescription(reason)
        : getReasonDescription(data?.errorName);
    } else {
      console.error("unknown cause", { cause });
    }
  } else if (error instanceof CallExecutionError) {
    const { cause } = error;

    if (cause instanceof ExecutionRevertedError) {
      const [, _reason] = cause.details?.split(":");
      const reason = _reason?.trim();
      description = getReasonDescription(reason);
    } else {
      console.error("unknown cause", { cause });
    }
  } else {
    const { cause } = error;

    if (hasKey("description", cause)) {
      description = cause.description;
    } else if (hasKey("detail", cause)) {
      const details = detailsMapping[cause.detail];
      if (details) {
        message = details;
      } else {
        description = cause.detail;
      }
    } else {
      console.error("unknown error", { error, cause });
    }
  }

  useEffect(() => {
    const to = setTimeout(() => {
      setVisible(false);
    }, 10000);

    return () => {
      clearTimeout(to);
    };
  }, [error]);

  useEffect(() => {
    if (swiped) {
      setVisible(false);
    }
  }, [swiped]);

  useEffect(() => {
    if (!visible) {
      onClose?.();
    }
  }, [visible, onClose]);

  return (
    <div className={cn("item", { visible })} onClick={() => setVisible(false)}>
      <NotificationComponent>
        <div className="notification__icon">
          <img
            src={
              (isWithExactMessage(error) && error.imgSrc
                ? error.imgSrc
                : undefined) ?? "/images/tip.svg"
            }
            alt=""
          />
        </div>
        <div>
          <ErrorMessage>{message}</ErrorMessage>
          {description?.length > 0 && (
            <ErrorDescription>{description}</ErrorDescription>
          )}
        </div>
      </NotificationComponent>
    </div>
  );
};

const MessageNotification = ({
  notification,
  swiped,
}: {
  notification: Notification;
  swiped?: boolean;
}) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(true);
    const to = setTimeout(() => {
      setVisible(false);
    }, NOTIFICATION_VISIBLE_MS);
    return () => {
      clearTimeout(to);
    };
  }, [notification]);

  useEffect(() => {
    if (swiped) {
      setVisible(false);
    }
  }, [swiped]);

  return (
    <div className={cn("item", { visible })} onClick={() => setVisible(false)}>
      <NotificationComponent>
        <div className="notification__icon">
          <img src={notification.imgSrc ?? "/images/tip.svg"} alt="" />
        </div>
        <div>
          <ErrorMessage>{notification.message}</ErrorMessage>
        </div>
      </NotificationComponent>
    </div>
  );
};

interface NotificationProps {
  ignore500?: boolean;
  ignoreUpstreamError?: boolean;
}

const Errors = ({ ignore500, ignoreUpstreamError }: NotificationProps) => {
  const [error, setError] = useState<Error>();
  // fixme dirty hack
  const [errors, setErrors] = useState<Record<string, Error | undefined>>({});

  const [notification, setNotification] = useState<Notification>();
  const [swipedNotification, setSwipedNotification] = useState(false);

  const { error$, notification$ } = useRx();

  useEffect(() => {
    const sub = error$.subscribe((nextError) => {
      setSwipedNotification(false);
      setError(nextError);
    });

    return () => {
      sub.unsubscribe();
    };
  }, [error$]);

  useEffect(() => {
    const sub = notification$.subscribe((nextNotification) => {
      setSwipedNotification(false);
      setNotification(nextNotification);
    });

    return () => {
      sub.unsubscribe();
    };
  }, [notification$]);

  const handlers = useSwipeable({
    onSwipedUp: () => setSwipedNotification(true),
    ...SWIPEABLE_CONFIG,
  });

  // fixme dirty hack
  useEffect(() => {
    if (error) {
      const id = `${Math.random()}`;
      setErrors((prev) => ({ ...prev, [id]: error }));
    }
  }, [error]);

  function onClose(id: string) {
    setTimeout(() => {
      setErrors((errors) => ({ ...errors, [id]: undefined }));
    }, 5000);
  }

  return (
    <Wrapper {...handlers}>
      {getEntries(errors)
        .filter(([, e]) => {
          if (!e) {
            return false;
          }

          const httpCode: number | undefined = (e.cause as any)?.code;

          if (ignore500 && httpCode === 500) {
            console.warn("suppressed error", e);
            return false;
          }
          if (
            ignoreUpstreamError &&
            e.message.toLowerCase().includes("upstream")
          ) {
            console.warn("suppressed error", e);
            return false;
          }

          return true;
        })
        .map(([id, error]) => {
          if (
            !error ||
            (isWithHiddenProp(error) && error.hidden) ||
            (!error.message && isWithExactMessage(error) && !error.imgSrc)
          ) {
            return <Noop key={id} />;
          }

          return (
            <ErrorNotification
              key={id}
              error={error}
              swiped={swipedNotification}
              onClose={onClose.bind(null, id)}
            />
          );
        })}
      {!!notification && (
        <MessageNotification
          notification={notification}
          swiped={swipedNotification}
        />
      )}
    </Wrapper>
  );
};

export default Errors;
