import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/packages/marginly-app/src/app/client.tsx");
import(/* webpackMode: "eager" */ "/app/packages/marginly-app/src/app/styles.css");
import(/* webpackMode: "eager" */ "/app/packages/marginly-app/src/components/common/notifications.tsx");
import(/* webpackMode: "eager" */ "/app/packages/marginly-app/src/components/common/wrapper.styled.tsx");
import(/* webpackMode: "eager" */ "/app/packages/marginly-app/src/components/ui/ip-stub/ip-stub.tsx");
import(/* webpackMode: "eager" */ "/app/packages/marginly-app/src/contexts/partner/partner.tsx");
import(/* webpackMode: "eager" */ "/app/packages/marginly-app/src/contexts/rx/index.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/styles/fonts.css");
import(/* webpackMode: "eager" */ "/app/packages/ui/styles/reset.css");
import(/* webpackMode: "eager" */ "/app/packages/ui/styles/theme.css")