import { firstValueFrom, ReplaySubject } from "rxjs";

interface WithHiddenProp {
  hidden?: boolean;
}

interface WithExactMessage {
  exact?: boolean;
  imgSrc?: string;
}

export const isWithHiddenProp = (obj: unknown): obj is WithHiddenProp =>
  !!obj && Reflect.has(obj, "hidden");

export const isWithExactMessage = (obj: unknown): obj is WithExactMessage =>
  !!obj && Reflect.has(obj, "exact");

export const withHidden = <E extends Error>(
  e: E,
  hidden?: boolean,
): E & WithHiddenProp => Object.assign(e, { hidden });

export const withExactMessage = <E extends Error>(
  e: E,
  imgSrc?: string,
): E & WithExactMessage => Object.assign(e, { exact: true, imgSrc });

const cloneError = <E extends Error>(error: E): E =>
  Object.assign(Object.create(Object.getPrototypeOf(error)), error);

export const displayLastError = (subj$: ReplaySubject<Error>) => {
  firstValueFrom(subj$).then((err) => {
    const unhidden = withHidden(cloneError(err), false);
    subj$.next(unhidden);
  });
};
