import type { ReadonlyURLSearchParams } from "next/navigation";

import type { PositionDirection } from "@/components/trade/state";
import type { Camelize } from "@/types/core";

import { snakeToCamel } from "./core";

const UTM_PARAMS = [
  "utm_campaign",
  "utm_source",
  "utm_medium",
  "utm_content",
  "utm_term",
] as const;

export const getUtmFromSearchParams = (
  s: ReadonlyURLSearchParams | URLSearchParams,
) => {
  const result: Partial<Record<Camelize<(typeof UTM_PARAMS)[number]>, string>> =
    {};
  let hasUtmParams = false;

  for (let [k, v] of s.entries()) {
    const param = UTM_PARAMS.find((el) => el.toLowerCase() === k.toLowerCase());

    if (param) {
      result[snakeToCamel(param)] = v;
      hasUtmParams = true;
    }
  }

  if (!hasUtmParams) return undefined;
  return result;
};

const trackEvent = (eventName: string, options: object = {}) =>
  (globalThis as any)?.gtag?.("event", eventName, options);

type SourcePage =
  | "homepage"
  | "trade"
  | "earn"
  | "lend-info"
  | "watch-position"
  | "boostrap";

export const trackSessionStart = () => trackEvent("session_start");
export const trackSignInClick = () => trackEvent("sign_in_click");

export const trackEarnStart = (source: SourcePage) =>
  trackEvent("earn_start", { source });

export const trackSwapStart = (source: SourcePage) =>
  trackEvent("swap_start", { source });

export const trackSwapClick = () => trackEvent("swap_click");
export const trackSwapSuccess = () => trackEvent("swap_success");
export const trackSwapError = (error: string) =>
  trackEvent("swap_error", { error });

export const trackTradeStart = (source: SourcePage, address: string) =>
  trackEvent("trade_start", { source, address });
export const trackTradeHomeScroll = (source: SourcePage, address: string) =>
  trackEvent("trade_home_scroll", { source, address });
export const trackTradeSignInClick = () => trackEvent("trade_sign_in_click");
export const trackTradeApproveClick = (
  direction: "long" | "short" | "swap" | undefined,
) => trackEvent("trade_approve_click", { direction });
export const trackTradeWithdrawClick = () => trackEvent("trade_withdraw_click");
export const trackTradeSwapClick = () => trackEvent("trade_swap_click");
export const trackTradeClick = (direction: PositionDirection) =>
  trackEvent("trade_click", { direction });
export const trackTradeSuccess = (
  direction: PositionDirection,
  amount: string,
) => trackEvent("trade_success", { direction, amount });

export const trackClosePositionClick = () => trackEvent("close_position_click");
export const trackClosePositionSuccess = () =>
  trackEvent("close_position_success");
export const trackClosePositionError = (error: string) =>
  trackEvent("close_position_error", { error });

export const trackLendInfoStart = (source: SourcePage) =>
  trackEvent("lend_info_start", { source });

export const trackDepositStart = (source: SourcePage) =>
  trackEvent("deposit_start", { source });
export const trackDepositClick = () => trackEvent("deposit_click");
export const trackDepositApproveClick = () =>
  trackEvent("deposit_approve_click");
export const trackDepositSuccess = () => trackEvent("deposit_success");
export const trackDepositError = (error: string) =>
  trackEvent("deposit_error", { error });

export const trackWithdrawStart = (source: SourcePage) =>
  trackEvent("withdraw_start", { source });
trackEvent("withdraw_approve_click");
export const trackWithdrawSuccess = () => trackEvent("withdraw_success");
export const trackWithdrawError = (error: string) =>
  trackEvent("withdraw_error", { error });

export const trackWatchPositionStart = (source: SourcePage) =>
  trackEvent("watch_position_start", { source });

export const trackIncreaseLeverageStart = () =>
  trackEvent("increase_leverage_start");
export const trackSubscribeNewsletterClick = () =>
  trackEvent("subscribe_newsletter_click");
export const trackSubscribeNewsletterSuccess = () =>
  trackEvent("subscribe_newsletter_success");
export const trackSubscribeNewsletterError = () =>
  trackEvent("subscribe_newsletter_error");

export const trackBootstrapVisit = () => trackEvent("bootstrap_visit");
export const trackBootstrapPoolClick = (
  poolAddress: string,
  poolName: string,
) => trackEvent("bootstrap_pool_click", { poolAddress, poolName });
export const trackBootstrapShareLinkClick = () =>
  trackEvent("bootstrap_share_link_click");
export const trackEarnPageClick = () =>
  trackEvent("bootstrap_earn_page_link_click");
export const trackSparkPageClick = () =>
  trackEvent("bootstrap_spark_page_link_click");
export const trackBootstrapAuditReportClick = () =>
  trackEvent("bootstrap_audit_report_click");
export const trackBootstrapStartEarningClick = () =>
  trackEvent("bootstrap_start_earning_click");

export const trackSocialClick = (
  social: "discord" | "telegram" | "twitter" | "github" | "medium",
) => trackEvent("social_click", { social });

export const trackFooterLinkClick = (
  footerLink:
    | "quantstamp"
    | "risk dashboard"
    | "terms and conditions"
    | "privacy policy"
    | "documents"
    | "audit",
) => trackEvent("footer_link_click", { footerLink });
