import { useSearchParams } from "next/navigation";
import { useCallback, useEffect, useRef } from "react";

import { UTM_CACHE } from "@/constants/cookies";
import { getUtmFromSearchParams } from "@/util/tracking";

export function useSaveTracking() {
  const ready = useRef(false);
  const params = useSearchParams();

  useEffect(() => {
    if (!ready.current) {
      const utm = getUtmFromSearchParams(params);

      if (utm) {
        sessionStorage.setItem(UTM_CACHE, JSON.stringify(utm));
      }
    }

    ready.current = true;
  }, [params]);
}

export function useMakeSendTracking() {
  return useCallback((baseurl: string, address: string) => {
    const utm = sessionStorage.getItem(UTM_CACHE);

    if (utm) {
      const url = `${baseurl}/analytics/${address}/utm`;
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      fetch(url, {
        headers,
        method: "POST",
        body: utm,
      }).catch(console.error);
    }
  }, []);
}
