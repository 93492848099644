import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    max-width: 324px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .image-wrapper {
    display: flex;
    padding: 24px;
    justify-content: center;
    align-items: center;

    border-radius: 48px;
    background: rgba(240, 165, 204, 0.16);
  }

  .text-wrapper {
    text-align: center;
    padding: 0 8px;
  }
`;

export const Button = styled.a`
  display: flex;
  height: 64px;
  padding: spacing/space-0 var(--spacing-space-48, 48px);
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: var(--rounding-radius-m, 16px);
  background: var(--fill-primary, #000);

  color: var(--text-invert-primary, #fff);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-variation-settings: "wght" 700;
  line-height: 20px; /* 125% */
  letter-spacing: 0.48px;
`;
